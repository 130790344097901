import { isEmpty } from '../../validation/is-empty';

import {
    RESET_CURRENT_USER,
    SET_CURRENT_USER,
    SET_API_VERSION
} from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: {},
    apiVersion: ''
};

export default function(state = initialState, action)
{
    switch (action.type)
    {
        case RESET_CURRENT_USER:
            return { ...initialState };
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case SET_API_VERSION:
            return {
                ...state,
                apiVersion: action.payload
            };
        default:
            return state;
    }
};
