
import { 
    CLEAR_ERRORS,
    GET_ERRORS
} from './types';

import { logoutUser } from './authActions';

export const clearErrors = () => 
{
    return {
        type: CLEAR_ERRORS,
        payload: {}
    };
};

export const handleError = (err) => dispatch => 
{
    dispatch(handleCustomError(null, err));
};

export const handleCustomError = (errorIndex, err) => dispatch =>
{
    let payload = {};

    if ('undefined' !== typeof err.response 
        && 401 === err.response.status)
    {
        dispatch(logoutUser());
    }
    else 
    {
        payload = {};

        if ('undefined' !== typeof err.response 
            && 'undefined' !== typeof err.response.data 
            && 'undefined' !== typeof err.response.data.result
            && null !== err.response.data.result)
        {
            payload = err.response.data.result;
        }
        else if ('undefined' !== typeof err.message 
            && 'string' === typeof err.message)
        {
            payload[errorIndex] = err.message;
        }
        else if ('undefined' !== typeof err
            && 'string' === typeof err)
        {
            payload[errorIndex] = err;
        }

        dispatch({ type: GET_ERRORS, payload: payload });
    }
};
