import axios from 'axios';
import setAuthToken from '../../utils-auth/setAuthToken';
import jwt_decode from 'jwt-decode';

import { 
    SET_CURRENT_USER,
    RESET_CURRENT_USER,
    SET_API_VERSION,
    RESET_WEB3_ACCOUNT,
    RESET_ERC20_TOKENS,
    RESET_ERC20_TOKEN_GENERATORS,
    RESET_ERC20_CROWDSALES,
    RESET_ERC20_CROWDSALE_GENERATORS,
    RESET_ERC721_TOKENS,
    RESET_ERC721_TOKEN_GENERATORS,
    RESET_USERS,
    RESET_LOGS,
    RESET_NETWORK_FEES,
    RESET_SUBSCRIPTIONS_MANAGERS
} from './types';

import {
    clearErrors,
    handleError,
    handleCustomError
} from './errorActions';

export const registerUser = (userData, history) => dispatch =>
{
    dispatch(clearErrors());

    axios
        .post('/api/users/register', userData)
        .then(res => 
            {
                history.push('/login');
            })
        .catch(err => 
            {
                dispatch(handleCustomError('name', err));
            });
};

export const loginUser = userData => dispatch =>
{
    dispatch(clearErrors());
    
    axios
        .post('/api/users/login', userData)
        .then(res => 
            {
                // save to local storage
                const { token } = res.data.result;
                // set token 
                localStorage.setItem("jwtToken", token);
                // set token to auth header
                setAuthToken(token);
                // decode token to get user data
                const decoded = jwt_decode(token);
                // set current user
                dispatch(setCurrentUser(decoded));
            })
        .catch(err => 
            {
                dispatch(handleCustomError('email', err));
            });
};

export const setCurrentUser = (decoded) => 
{
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const getCurrentUser = (done) => dispatch => 
{
    axios
        .get('/api/users/current')
        .then(res => 
            {
                const { version } = res.data.result;
                dispatch({
                    type: SET_API_VERSION,
                    payload: version
                });

                done(true);
            })
        .catch(err => { 
            dispatch(handleError(err));

            done(false);
        });
};

export const logoutUser = () => dispatch => 
{
    console.log('user logout');

    dispatch(clearErrors());

    // remove token from local storage
    localStorage.removeItem('jwtToken');
    // remove the auth header for future requests
    setAuthToken(false);
    // set the current user to {} which will also set isAuthenticated to false
    dispatch({ type: RESET_CURRENT_USER, payload: {} });
    // remove any session data
    dispatch({ type: RESET_WEB3_ACCOUNT, payload: {}});
    dispatch({ type: RESET_ERC20_TOKENS, payload: {} });
    dispatch({ type: RESET_ERC20_TOKEN_GENERATORS, payload: {} });
    dispatch({ type: RESET_ERC20_CROWDSALES, payload: {} });
    dispatch({ type: RESET_ERC20_CROWDSALE_GENERATORS, payload: {} });
    dispatch({ type: RESET_ERC721_TOKENS, payload: {} });
    dispatch({ type: RESET_ERC721_TOKEN_GENERATORS, payload: {} });
    dispatch({ type: RESET_USERS, payload: {} });
    dispatch({ type: RESET_LOGS, payload: {} });
    dispatch({ type: RESET_NETWORK_FEES, payload: {} });
    dispatch({ type: RESET_SUBSCRIPTIONS_MANAGERS, payload: {} });
    // TODO: add more session data removal code here;
};

export const changePassword = (passwordData, done) => dispatch =>
{
    dispatch(clearErrors());

    axios
    .post('/api/users/change-password', passwordData)
    .then(res => {
        done(true);
    })
    .catch(err => {
        dispatch(handleCustomError('currentPassword', err));
        done(false);
    });
};

export const recoverPassword = (accountData, done) => dispatch => 
{
    dispatch(clearErrors());

    axios
    .post('/api/users/recover-password', accountData)
    .then(res => {
        done(true);
    })
    .catch(err => {
        dispatch(handleCustomError('email', err));
        done(false);
    });
};

export const resetPassword = (accountData, done) => dispatch => 
{
    dispatch(clearErrors());

    axios
    .post('/api/users/reset-password', accountData)
    .then(res => {
        done(true);
    })
    .catch(err => {
        dispatch(handleCustomError('email', err));
        done(false);
    });
};

export const deleteAccount = (passwordData, done) => dispatch =>
{
    axios
    .post('/api/users/delete', passwordData)
    .then(res => {
        done(true);
    })
    .catch(err => {
        dispatch(handleCustomError('deletePassword', err));
        done(false);
    });
};
