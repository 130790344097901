import {
    RESET_ERC20_TOKEN_GENERATORS,
    GET_ERC20_TOKEN_GENERATORS,
    GET_ERC20_TOKEN_GENERATOR,
    ADD_ERC20_TOKEN_GENERATOR,
    UPDATE_ERC20_TOKEN_GENERATOR,
    DELETE_ERC20_TOKEN_GENERATOR
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc20tokenGenerators: [],
    erc20tokenGenerator: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC20_TOKEN_GENERATORS:
            return { ...initialState };
        case GET_ERC20_TOKEN_GENERATORS:
            return {
                ...state,
                erc20tokenGenerators: action.payload,
                initialSync: true
            };
        case GET_ERC20_TOKEN_GENERATOR:
            return {
                ...state,
                erc20tokenGenerator: action.payload
            };
        case ADD_ERC20_TOKEN_GENERATOR:
            return {
                ...state,
                erc20tokenGenerators: [action.payload, ...state.erc20tokenGenerators]
            };
        case DELETE_ERC20_TOKEN_GENERATOR:
            return {
                ...state,
                erc20tokenGenerators: state.erc20tokenGenerators.filter(erc20tokenGenerator => erc20tokenGenerator._id !== action.payload._id)
            };
        case UPDATE_ERC20_TOKEN_GENERATOR:
            let erc20tokenGenerator = action.payload;
            let index = state.erc20tokenGenerators.map(erc20tokenGenerator => erc20tokenGenerator._id).indexOf(erc20tokenGenerator._id);
            state.erc20tokenGenerators[index] = erc20tokenGenerator;
            
            return state;
        default:
            return state;
    }
};
