
import { 
    RESET_WEB3_ACCOUNT,
    CLEAR_WEB3_ACCOUNT,
    UPDATE_WEB3_ACCOUNT,
    CLEAR_NETWORK_STATUS,
    UPDATE_NETWORK_STATUS
} from '../actions/types';

const initialState = { 
    isEnabled: false,
    web3: null,
    networkId: null,
    networkType: null,
    account: null,
    gasPrice: null,
    blockNumber: null,
};

export default function(state=initialState, action)
{
    switch (action.type)
    {
        case RESET_WEB3_ACCOUNT:
            return { ...initialState };
        case CLEAR_WEB3_ACCOUNT:
            return {
                ...state,
                isEnabled: false,
                web3: null,
                networkId: null,
                networkType: null,
                account: null
            };
        case UPDATE_WEB3_ACCOUNT:
            let {
                web3,
                networkId,
                networkType,
                account
            } = action.payload;

            return {
                ...state,
                isEnabled: (null !== account),
                web3: web3,
                networkId: networkId,
                networkType: networkType,
                account: account
            };
        case CLEAR_NETWORK_STATUS:
            return {
                ...state,
                gasPrice: null,
                blockNumber: null
            };
        case UPDATE_NETWORK_STATUS:
            let {
                gasPrice,
                blockNumber
            } = action.payload;

            return {
                ...state,
                gasPrice: gasPrice,
                blockNumber: blockNumber
            };
        default:
            return state;
    }
};
