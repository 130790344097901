
import { 
    RESET_NETWORK_FEES,
    CLEAR_NETWORK_FEES,
    UPDATE_NETWORK_FEES
} from '../actions/types';

const initialState = { 
    feesEnabled: false,
    feeSubscription: null,
    subscriptionTimestamp: null,
    subscriptionIsValid: null,
    feeERC20TokenCapped: null,
    feeERC20TokenCappedBurnable: null,
    feeERC20TokenNotCapped: null,
    feeERC20TokenNotCappedBurnable: null,
    feeERC20CrowdsaleCapped: null,
    feeERC20CrowdsaleTimed: null,
    feeERC721TokenMintable: null,
    feeERC721TokenMintableBurnable: null,
    feeERC721TokenMintableAutoId: null,
    feeERC721TokenMintableAutoIdBurnable: null
};

export default function(state=initialState, action)
{
    switch (action.type)
    {
        case RESET_NETWORK_FEES:
            return { ...initialState };
        case CLEAR_NETWORK_FEES:
            return {
                ...state,
                feesEnabled: false,
                feeSubscription: null,
                subscriptionTimestamp: null,
                subscriptionIsValid: null,
                feeERC20TokenCapped: null,
                feeERC20TokenCappedBurnable: null,
                feeERC20TokenNotCapped: null,
                feeERC20TokenNotCappedBurnable: null,
                feeERC20CrowdsaleCapped: null,
                feeERC20CrowdsaleTimed: null,
                feeERC721TokenMintable: null,
                feeERC721TokenMintableBurnable: null,
                feeERC721TokenMintableAutoId: null,
                feeERC721TokenMintableAutoIdBurnable: null            
            };
        case UPDATE_NETWORK_FEES:
            let {
                feesEnabled,
                feeSubscription,
                subscriptionTimestamp,
                subscriptionIsValid,
                feeERC20TokenCapped,
                feeERC20TokenCappedBurnable,
                feeERC20TokenNotCapped,
                feeERC20TokenNotCappedBurnable,
                feeERC20CrowdsaleCapped,
                feeERC20CrowdsaleTimed,
                feeERC721TokenMintable,
                feeERC721TokenMintableBurnable,
                feeERC721TokenMintableAutoId,
                feeERC721TokenMintableAutoIdBurnable 
            } = action.payload;

            return {
                ...state,
                feesEnabled: feesEnabled,
                feeSubscription: feeSubscription,
                subscriptionTimestamp: subscriptionTimestamp,
                subscriptionIsValid: subscriptionIsValid,
                feeERC20TokenCapped: feeERC20TokenCapped,
                feeERC20TokenCappedBurnable: feeERC20TokenCappedBurnable,
                feeERC20TokenNotCapped: feeERC20TokenNotCapped,
                feeERC20TokenNotCappedBurnable: feeERC20TokenNotCappedBurnable,
                feeERC20CrowdsaleCapped: feeERC20CrowdsaleCapped,
                feeERC20CrowdsaleTimed: feeERC20CrowdsaleTimed,
                feeERC721TokenMintable: feeERC721TokenMintable,
                feeERC721TokenMintableBurnable: feeERC721TokenMintableBurnable,
                feeERC721TokenMintableAutoId: feeERC721TokenMintableAutoId,
                feeERC721TokenMintableAutoIdBurnable: feeERC721TokenMintableAutoIdBurnable
            };
        default:
            return state;
    }
};
