import {
    RESET_ERC721_TOKEN_GENERATORS,
    GET_ERC721_TOKEN_GENERATORS,
    GET_ERC721_TOKEN_GENERATOR,
    ADD_ERC721_TOKEN_GENERATOR,
    UPDATE_ERC721_TOKEN_GENERATOR,
    DELETE_ERC721_TOKEN_GENERATOR
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc721tokenGenerators: [],
    erc721tokenGenerator: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC721_TOKEN_GENERATORS:
            return { ...initialState };
        case GET_ERC721_TOKEN_GENERATORS:
            return {
                ...state,
                erc721tokenGenerators: action.payload,
                initialSync: true
            };
        case GET_ERC721_TOKEN_GENERATOR:
            return {
                ...state,
                erc721tokenGenerator: action.payload
            };
        case ADD_ERC721_TOKEN_GENERATOR:
            return {
                ...state,
                erc721tokenGenerators: [action.payload, ...state.erc721tokenGenerators]
            };
        case DELETE_ERC721_TOKEN_GENERATOR:
            return {
                ...state,
                erc721tokenGenerators: state.erc721tokenGenerators.filter(erc721tokenGenerator => erc721tokenGenerator._id !== action.payload._id)
            };
        case UPDATE_ERC721_TOKEN_GENERATOR:
            let erc721tokenGenerator = action.payload;
            let index = state.erc721tokenGenerators.map(erc721tokenGenerator => erc721tokenGenerator._id).indexOf(erc721tokenGenerator._id);
            state.erc721tokenGenerators[index] = erc721tokenGenerator;
            
            return state;
        default:
            return state;
    }
};
