import {
    RESET_SUBSCRIPTIONS_MANAGERS,
    GET_SUBSCRIPTIONS_MANAGERS,
    GET_SUBSCRIPTIONS_MANAGER,
    ADD_SUBSCRIPTIONS_MANAGER,
    UPDATE_SUBSCRIPTIONS_MANAGER,
    DELETE_SUBSCRIPTIONS_MANAGER
} from '../actions/types';

const initialState = {
    initialSync: false,
    subscriptionsManagers: [],
    subscriptionsManager: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_SUBSCRIPTIONS_MANAGERS:
            return { ...initialState };
        case GET_SUBSCRIPTIONS_MANAGERS:
            return {
                ...state,
                subscriptionsManagers: action.payload,
                initialSync: true
            };
        case GET_SUBSCRIPTIONS_MANAGER:
            return {
                ...state,
                subscriptionsManager: action.payload
            };
        case ADD_SUBSCRIPTIONS_MANAGER:
            return {
                ...state,
                subscriptionsManagers: [action.payload, ...state.subscriptionsManagers]
            };
        case DELETE_SUBSCRIPTIONS_MANAGER:
            return {
                ...state,
                subscriptionsManagers: state.subscriptionsManagers.filter(subscriptionsManager => subscriptionsManager._id !== action.payload._id)
            };
        case UPDATE_SUBSCRIPTIONS_MANAGER:
            let subscriptionsManager = action.payload;
            let index = state.subscriptionsManagers.map(subscriptionsManager => subscriptionsManager._id).indexOf(subscriptionsManager._id);
            state.subscriptionsManagers[index] = subscriptionsManager;
            
            return state;
        default:
            return state;
    }
};
