import {
    RESET_USERS,
    GET_USERS,
    UPDATE_USER,
    DELETE_USER
} from '../actions/types';

const initialState = {
    initialSync: false,
    users: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_USERS:
            return { ...initialState };
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                initialSync: true
            };
        case UPDATE_USER:
            let user = action.payload;
            let index = state.users.map(user => user._id).indexOf(user._id);
            state.users[index] = user;

            return state;
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user._id !== action.payload._id)
            };
        default:
            return state;
    }
};
