import {
    RESET_ERC20_CROWDSALES,
    GET_ERC20_CROWDSALES,
    GET_ERC20_CROWDSALE,
    ADD_ERC20_CROWDSALE,
    UPDATE_ERC20_CROWDSALE,
    ISSUE_ERC20_CROWDSALE,
    DELETE_ERC20_CROWDSALE
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc20crowdsales: [],
    erc20crowdsale: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC20_CROWDSALES:
            return { ...initialState };
        case GET_ERC20_CROWDSALES:
            return {
                ...state,
                erc20crowdsales: action.payload,
                initialSync: true
            };
        case GET_ERC20_CROWDSALE:
            return {
                ...state,
                erc20crowdsale: action.payload
            };
        case ADD_ERC20_CROWDSALE:
        case ISSUE_ERC20_CROWDSALE:
            return {
                ...state,
                erc20crowdsales: [action.payload, ...state.erc20crowdsales]
            };
        case DELETE_ERC20_CROWDSALE:
            return {
                ...state,
                erc20crowdsales: state.erc20crowdsales.filter(erc20crowdsale => erc20crowdsale._id !== action.payload._id)
            };
        case UPDATE_ERC20_CROWDSALE:
            let erc20crowdsale = action.payload;
            let index = state.erc20crowdsales.map(erc20crowdsale => erc20crowdsale._id).indexOf(erc20crowdsale._id);
            state.erc20crowdsales[index] = erc20crowdsale;
            
            return state;
        default:
            return state;
    }
};
