import { combineReducers } from 'redux';

import authReducer from './authReducer';
import erc20CrowdsaleReducer from './erc20CrowdsaleReducer';
import erc20CrowdsaleGeneratorReducer from './erc20CrowdsaleGeneratorReducer';
import erc20TokenReducer from './erc20TokenReducer';
import erc20TokenGeneratorReducer from './erc20TokenGeneratorReducer';
import erc721TokenReducer from './erc721TokenReducer';
import erc721TokenGeneratorReducer from './erc721TokenGeneratorReducer';
import errorReducer from './errorReducer';
import feesReducer from './feesReducer';
import logsReducer from './logsReducer';
import usersReducer from './usersReducer';
import web3Reducer from './web3Reducer';
import subscriptionsManagerReducer from './subscriptionsManagerReducer';

export default combineReducers({
    auth: authReducer,
    erc20crowdsale: erc20CrowdsaleReducer,
    erc20crowdsaleGenerator: erc20CrowdsaleGeneratorReducer,
    erc20token: erc20TokenReducer,
    erc20tokenGenerator: erc20TokenGeneratorReducer,
    erc721token: erc721TokenReducer,
    erc721tokenGenerator: erc721TokenGeneratorReducer,
    errors: errorReducer,
    fees: feesReducer,
    logs: logsReducer,
    users: usersReducer,
    web3: web3Reducer,
    subscriptionsManager: subscriptionsManagerReducer
});
