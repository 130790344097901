import {
    RESET_ERC20_CROWDSALE_GENERATORS,
    GET_ERC20_CROWDSALE_GENERATORS,
    GET_ERC20_CROWDSALE_GENERATOR,
    ADD_ERC20_CROWDSALE_GENERATOR,
    UPDATE_ERC20_CROWDSALE_GENERATOR,
    DELETE_ERC20_CROWDSALE_GENERATOR
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc20crowdsaleGenerators: [],
    erc20crowdsaleGenerator: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC20_CROWDSALE_GENERATORS:
            return { ...initialState };
        case GET_ERC20_CROWDSALE_GENERATORS:
            return {
                ...state,
                erc20crowdsaleGenerators: action.payload,
                initialSync: true
            };
        case GET_ERC20_CROWDSALE_GENERATOR:
            return {
                ...state,
                erc20crowdsaleGenerator: action.payload
            };
        case ADD_ERC20_CROWDSALE_GENERATOR:
            return {
                ...state,
                erc20crowdsaleGenerators: [action.payload, ...state.erc20crowdsaleGenerators]
            };
        case DELETE_ERC20_CROWDSALE_GENERATOR:
            return {
                ...state,
                erc20crowdsaleGenerators: state.erc20crowdsaleGenerators.filter(erc20crowdsaleGenerator => erc20crowdsaleGenerator._id !== action.payload._id)
            };
        case UPDATE_ERC20_CROWDSALE_GENERATOR:
            let erc20crowdsaleGenerator = action.payload;
            let index = state.erc20crowdsaleGenerators.map(erc20crowdsaleGenerator => erc20crowdsaleGenerator._id).indexOf(erc20crowdsaleGenerator._id);
            state.erc20crowdsaleGenerators[index] = erc20crowdsaleGenerator;
            
            return state;
        default:
            return state;
    }
};
