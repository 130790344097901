import {
    RESET_ERC721_TOKENS,
    GET_ERC721_TOKENS,
    GET_ERC721_TOKEN,
    ADD_ERC721_TOKEN,
    UPDATE_ERC721_TOKEN,
    ISSUE_ERC721_TOKEN,
    DELETE_ERC721_TOKEN
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc721tokens: [],
    erc721token: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC721_TOKENS:
            return { ...initialState };
        case GET_ERC721_TOKENS:
            return {
                ...state,
                erc721tokens: action.payload,
                initialSync: true
            };
        case GET_ERC721_TOKEN:
            return {
                ...state,
                erc721token: action.payload
            };
        case ADD_ERC721_TOKEN:
        case ISSUE_ERC721_TOKEN:
            return {
                ...state,
                erc721tokens: [action.payload, ...state.erc721tokens]
            };
        case DELETE_ERC721_TOKEN:
            return {
                ...state,
                erc721tokens: state.erc721tokens.filter(erc721token => erc721token._id !== action.payload._id)
            };
        case UPDATE_ERC721_TOKEN:
            let erc721token = action.payload;
            let index = state.erc721tokens.map(erc721token => erc721token._id).indexOf(erc721token._id);
            state.erc721tokens[index] = erc721token;
            
            return state;
        default:
            return state;
    }
};
