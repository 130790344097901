
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_API_VERSION = 'SET_API_VERSION';

export const RESET_WEB3_ACCOUNT = 'RESET_WEB3_ACCOUNT';
export const CLEAR_WEB3_ACCOUNT = 'CLEAR_WEB3_ACCOUNT';
export const UPDATE_WEB3_ACCOUNT = 'UPDATE_WEB3_ACCOUNT';
export const CLEAR_NETWORK_STATUS = 'CLEAR_NETWORK_STATUS';
export const UPDATE_NETWORK_STATUS = 'UPDATE_NETWORK_STATUS';

export const RESET_NETWORK_FEES = 'RESET_NETWORK_FEES';
export const CLEAR_NETWORK_FEES = 'CLEAR_NETWORK_FEES';
export const UPDATE_NETWORK_FEES = 'UPDATE_NETWORK_FEES';

export const RESET_ERC20_TOKENS = 'RESET_ERC20_TOKENS';
export const GET_ERC20_TOKENS = 'GET_ERC20_TOKENS';
export const GET_ERC20_TOKEN = 'GET_ERC20_TOKEN';
export const ADD_ERC20_TOKEN = 'ADD_ERC20_TOKEN';
export const UPDATE_ERC20_TOKEN = 'UPDATE_ERC20_TOKEN';
export const ISSUE_ERC20_TOKEN = 'ISSUE_ERC20_TOKEN';
export const DELETE_ERC20_TOKEN = 'DELETE_ERC20_TOKEN';

export const RESET_ERC20_CROWDSALES = 'RESET_ERC20_CROWDSALES';
export const GET_ERC20_CROWDSALES = 'GET_ERC20_CROWDSALES';
export const GET_ERC20_CROWDSALE = 'GET_ERC20_CROWDSALE';
export const ADD_ERC20_CROWDSALE = 'ADD_ERC20_CROWDSALE';
export const UPDATE_ERC20_CROWDSALE = 'UPDATE_ERC20_CROWDSALE';
export const ISSUE_ERC20_CROWDSALE = 'ISSUE_ERC20_CROWDSALE';
export const DELETE_ERC20_CROWDSALE = 'DELETE_ERC20_CROWDSALE';

export const RESET_ERC721_TOKENS = 'RESET_ERC721_TOKENS';
export const GET_ERC721_TOKENS = 'GET_ERC721_TOKENS';
export const GET_ERC721_TOKEN = 'GET_ERC721_TOKEN';
export const ADD_ERC721_TOKEN = 'ADD_ERC721_TOKEN';
export const UPDATE_ERC721_TOKEN = 'UPDATE_ERC721_TOKEN';
export const ISSUE_ERC721_TOKEN = 'ISSUE_ERC721_TOKEN';
export const DELETE_ERC721_TOKEN = 'DELETE_ERC721_TOKEN';

export const RESET_USERS = 'RESET_USERS';
export const GET_USERS = 'GET_USERS';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

export const RESET_LOGS = 'RESET_LOGS';
export const GET_LOGS = 'GET_LOGS';
export const DELETE_LOG = 'DELETE_LOG';

export const RESET_ERC20_TOKEN_GENERATORS = 'RESET_ERC20_TOKEN_GENERATORS';
export const GET_ERC20_TOKEN_GENERATORS = 'GET_ERC20_TOKEN_GENERATORS';
export const GET_ERC20_TOKEN_GENERATOR = 'GET_ERC20_TOKEN_GENERATOR';
export const ADD_ERC20_TOKEN_GENERATOR = 'ADD_ERC20_TOKEN_GENERATOR';
export const UPDATE_ERC20_TOKEN_GENERATOR = 'UPDATE_ERC20_TOKEN_GENERATOR';
export const DELETE_ERC20_TOKEN_GENERATOR = 'DELETE_ERC20_TOKEN_GENERATOR';

export const RESET_ERC20_CROWDSALE_GENERATORS = 'RESET_ERC20_CROWDSALE_GENERATORS';
export const GET_ERC20_CROWDSALE_GENERATORS = 'GET_ERC20_CROWDSALE_GENERATORS';
export const GET_ERC20_CROWDSALE_GENERATOR = 'GET_ERC20_CROWDSALE_GENERATOR';
export const ADD_ERC20_CROWDSALE_GENERATOR = 'ADD_ERC20_CROWDSALE_GENERATOR';
export const UPDATE_ERC20_CROWDSALE_GENERATOR = 'UPDATE_ERC20_CROWDSALE_GENERATOR';
export const DELETE_ERC20_CROWDSALE_GENERATOR = 'DELETE_ERC20_CROWDSALE_GENERATOR';

export const RESET_ERC721_TOKEN_GENERATORS = 'RESET_ERC721_TOKEN_GENERATORS';
export const GET_ERC721_TOKEN_GENERATORS = 'GET_ERC721_TOKEN_GENERATORS';
export const GET_ERC721_TOKEN_GENERATOR = 'GET_ERC721_TOKEN_GENERATOR';
export const ADD_ERC721_TOKEN_GENERATOR = 'ADD_ERC721_TOKEN_GENERATOR';
export const UPDATE_ERC721_TOKEN_GENERATOR = 'UPDATE_ERC721_TOKEN_GENERATOR';
export const DELETE_ERC721_TOKEN_GENERATOR = 'DELETE_ERC721_TOKEN_GENERATOR';

export const RESET_SUBSCRIPTIONS_MANAGERS = 'RESET_SUBSCRIPTIONS_MANAGERS';
export const GET_SUBSCRIPTIONS_MANAGERS = 'GET_SUBSCRIPTIONS_MANAGERS';
export const GET_SUBSCRIPTIONS_MANAGER = 'GET_SUBSCRIPTIONS_MANAGER';
export const ADD_SUBSCRIPTIONS_MANAGER = 'ADD_SUBSCRIPTIONS_MANAGER';
export const UPDATE_SUBSCRIPTIONS_MANAGER = 'UPDATE_SUBSCRIPTIONS_MANAGER';
export const DELETE_SUBSCRIPTIONS_MANAGER = 'DELETE_SUBSCRIPTIONS_MANAGER';
