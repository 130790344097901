import {
    RESET_ERC20_TOKENS,
    GET_ERC20_TOKENS,
    GET_ERC20_TOKEN,
    ADD_ERC20_TOKEN,
    UPDATE_ERC20_TOKEN,
    ISSUE_ERC20_TOKEN,
    DELETE_ERC20_TOKEN
} from '../actions/types';

const initialState = {
    initialSync: false,
    erc20tokens: [],
    erc20token: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_ERC20_TOKENS:
            return { ...initialState };
        case GET_ERC20_TOKENS:
            return {
                ...state,
                erc20tokens: action.payload,
                initialSync: true
            };
        case GET_ERC20_TOKEN:
            return {
                ...state,
                erc20token: action.payload
            };
        case ADD_ERC20_TOKEN:
        case ISSUE_ERC20_TOKEN:
            return {
                ...state,
                erc20tokens: [action.payload, ...state.erc20tokens]
            };
        case DELETE_ERC20_TOKEN:
            return {
                ...state,
                erc20tokens: state.erc20tokens.filter(erc20token => erc20token._id !== action.payload._id)
            };
        case UPDATE_ERC20_TOKEN:
            let erc20token = action.payload;
            let index = state.erc20tokens.map(erc20token => erc20token._id).indexOf(erc20token._id);
            state.erc20tokens[index] = erc20token;
            
            return state;
        default:
            return state;
    }
};
