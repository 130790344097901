import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils-auth/setAuthToken';

import { setCurrentUser, logoutUser } from './redux/actions/authActions';

import { Provider } from 'react-redux';
import store from './redux/store/Store';

import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

//const Landing = React.lazy(() => import('./components/Pages/Landing/Landing'));
const Default = React.lazy(() => import('./components/Pages/Default/Default'));
const Privacy = React.lazy(() => import('./components/Pages/Legal/Privacy'));
const Terms = React.lazy(() => import('./components/Pages/Legal/Terms'));
const FAQ = React.lazy(() => import('./components/Pages/FAQ/FAQ'));
const API = React.lazy(() => import('./components/Pages/GeneratorsAPI/GeneratorsAPI'));

const Login = React.lazy(() => import('./components/Pages/Login/Login'));
const Register = React.lazy(() => import('./components/Pages/Register/Register'));
const PasswordRecover = React.lazy(() => import('./components/Pages/Password/PasswordRecover'));
const PasswordReset = React.lazy(() => import('./components/Pages/Password/PasswordReset'));
const Page404 = React.lazy(() => import('./components/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500/Page500'));

const DefaultLayout = React.lazy(() => import('./components/Containers/DefaultLayout/DefaultLayout'));

if (localStorage.jwtToken)
{
    // set auth token header
    setAuthToken(localStorage.jwtToken);
    // decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);
    // set user and is-authenticated
    store.dispatch(setCurrentUser(decoded));

    // check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime)
    {
      // logout the user
      store.dispatch(logoutUser());
      // redirect to login
      window.location.href = "/login";
    }
}

class App extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = { 
          web3: null,
          networkId: null,
          account: null
        };
    }

    componentDidMount()
    {
        // TOOD: add logic here;
    }

    componentWillUnmount()
    {
        // TODO: add logic here;
    }

    render() 
    {
        return (
            <Provider store={store}>
            <Router>
                <React.Suspense fallback={loading()}>
                <Switch>
                    {/*}<Route exact path="/" name="Landing Page" render={props => <Landing {...props}/>} />{*/}
                    <Route exact path="/" name="Default Page" render={props => <Default {...props}/>} />
                    <Route exact path="/privacy" name="Privacy" render={props => <Privacy {...props}/>} />
                    <Route exact path="/terms" name="Terms of Use" render={props => <Terms {...props}/>} />
                    <Route exact path="/faq" name="Frequently Asked Questions" render={props => <FAQ {...props}/>} />
                    <Route exact path="/api" name="Generators API" render={props => <API {...props}/>} />
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                    <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                    <Route exact path="/recover-password" name="Recover Password" render={props => <PasswordRecover {...props}/>} />
                    <Route exact path="/reset-password/:resetHash" name="Reset Password" render={props => <PasswordReset {...props}/>} />
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                    <Route exact path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
                    <Route name="Dashboard" render={props => <DefaultLayout {...props}/>} />
                </Switch>
                </React.Suspense>
            </Router>
            </Provider>
            );
    }
}

export default App;
