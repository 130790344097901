import {
    RESET_LOGS,
    GET_LOGS,
    DELETE_LOG
} from '../actions/types';

const initialState = {
    initialSync: false,
    logs: []
};

export default function(state = initialState, action)
{
    switch(action.type)
    {
        case RESET_LOGS:
            return { ...initialState };
        case GET_LOGS:
            return {
                ...state,
                logs: action.payload,
                initialSync: true
            };
        case DELETE_LOG:
            return {
                ...state,
                logs: state.logs.filter(log => log._id !== action.payload._id)
            };
        default:
            return state;
    }
};
